const timeOut = 3000;

export const onSuccessNotification = ( toast, message ) => {
    toast.success( message, {
        position: "top-right",
        timeout: timeOut,
        closeOnClick: false,
        draggable: true,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        toastClassName: 'vf-success'
    });
}

export const onErrorNotification = ( toast, message ) => {
    toast.error( message, {
        position: "top-right",
        timeout: timeOut,
        closeOnClick: false,
        draggable: true,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        toastClassName: 'vf-error'
    });
}

export const onInformationNotification = ( toast, message ) => {
    toast.info( message, {
        position: 'top-bottom',
        timeout: timeOut,
        closeOnClick: false,
        draggable: true,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
    });
};

export const onQuestionNotification = ( swal, title, text, confirmButtonText, cancelButtonText ) => {
    return swal.fire({
        title,
        text,
        icon: 'warning',
        iconColor: '#FFB400',
        confirmButtonColor: '#00cc92',
        confirmButtonText,
        showCancelButton: true,
        cancelButtonColor: '#6c757d',
        cancelButtonText,
    });
};

export const onConnectionErrorNotification = ( router, toast, message, redirectTo = '' ) => {
    toast.error( message, {
        position: "top-right",
        timeout: timeOut,
        closeOnClick: false,
        draggable: true,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        toastClassName: "vf-error"
    });

    if ( redirectTo ) {
        setTimeout(
            () => router.push({ name: redirectTo }),
            timeOut
        );
    }
};
