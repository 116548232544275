<template>
    <div id="nav-bar"
         class="l-navbar show-menu shadow"
    >
        <nav class="nav">
            <div>
                <a href="" class="nav_logo">
                    <img
                        class="m-2"
                        id="image_logo"
                        src="@/assets/images/icon-verifarma-1.svg"
                        style="justify-items: center; grid-template-columns: auto;"
                        width="150"
                        height="32"
                        alt="Logo Verifarma"
                    >

                    <img
                        class="d-none"
                        id="image_logo_reduced"
                        src="@/assets/images/icon-verifarma-reduced.png"
                        style="justify-items: center; grid-template-columns: auto;"
                        width="25"
                        height="25"
                        alt="Logo Verifarma"
                    >
                </a>

                <div class="nav_list">
                    <a class=""
                       v-for="(route, index) in routesList"
                       :class="getClass(route.meta.in_menu)"
                       :key="index"
                    >
                        <router-link
                            class="nav_link"
                            v-if="route.meta.in_menu"
                            :to="route.path"
                            :data-automation="`link-route-${ route.name }`"
                        >
                            <i v-if="route.meta.icon"
                               :class="route.meta.icon"
                               class='nav_icon'
                            ></i>
                            <span class="nav_name">{{ route.name }}</span>
                        </router-link>
                    </a>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
    import { computed, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';

    export default {
        name: 'vf-menu',
        setup() {
            const router = useRouter();
            const { t } = useI18n();
            const routesList = ref([]);

            const getRouteList = () => {
                routesList.value = router.getRoutes();
                routesList.value.forEach(route => {
                    if (route.name) {
                        route.name = t(`menu.${route.name}`);
                    }
                });
            };

            const getClass = (in_menu) => {
                return !in_menu ? 'd-none' : '';
            };

            getRouteList();

            return {
                routesList: computed(() => routesList.value),
                getClass
            };
        }
    }
</script>
