export const getFragmentCodesBySupportedCharacterId = ( state ) => ( supportedCharacterId ) => {
    return state.fragmentCodes[ supportedCharacterId ] || [];
}

export const getFragmentCodesSelectListBySupportedCharacterId = ( state ) => ( supportedCharacterId ) => {
    return state.fragmentCodesSelectList[ supportedCharacterId ] || [];
}

export const getFragmentCodeLengthById = ( state ) => ( fragmentCodeId, supportedCharacterId ) => {
    for (const fragmentCode of state.fragmentCodesSelectList[ supportedCharacterId ] ) {
        if ( fragmentCode.value === fragmentCodeId ) {
            return fragmentCode;
        }
    }
    return null;
}
