export const setLoading = ( state, value ) => {
    state.isLoading = value;
}

export const setCommodityIdentifications = ( state, value ) => {
    state.commodityIdentifications = value;
}

export const setCommodityIdentification = ( state, value ) => {
    state.commodityIdentification = value;
}

export const clearStateStore = ( state ) => {
    state.commodityIdentification = [];
    state.commodityIdentifications = [];
    state.isLoading = false;
}
