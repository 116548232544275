import { useStore } from 'vuex';
import { createRouter, createWebHistory } from 'vue-router';
import seriesCompositionRouter from '@/modules/seriesComposition/router';
import generatorRulesRouter from '@/modules/generatorRules/router';
import commodityIdentificationsRouter from '@/modules/commodityIdentifications/router';
import { clearModuleStore } from '@/helpers';

const Home = () => import('@/views/HomeView.vue');

const routes = [
    {
        path: '/',
        name: 'index',
        component: Home,
        meta: {
            in_menu: true,
            icon: 'fas fa-home',
            requiresAuth: true
        }
    },
    ...seriesCompositionRouter,
    ...generatorRulesRouter,
    ...commodityIdentificationsRouter
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        const token = localStorage.getItem('token');
        const tokenQuery = to.query.token;

        if (!token && !tokenQuery) {
            // TODO: in case it is not authenticated
            // next({
            //     path: '/login',
            //     query: {
            //         redirect: to.fullPath
            //     }
            // });
            next();
        } else {
            next();
        }
    } else {
        next();
    }

    const store = useStore();
    clearModuleStore( store, from, to );
});

export default router;
