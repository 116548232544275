import { useI18n } from "vue-i18n";
import {ref} from "vue";

export default function useVFTable () {
    const { t } = useI18n();
    const basePrefix = 'table';
    const dropdownMenuContainer = ref({});
    const dropdownMenuStates = ref({});

    const onSetDropdownMenuContainerRef = (el, commodityIdentificationId) => {
        dropdownMenuContainer.value[commodityIdentificationId] = el;
    };

    const onToggleDropdownMenu = ( id ) => {
        Object.keys( dropdownMenuStates.value ).forEach( key => {
            if ( key !== id ) {
                dropdownMenuStates.value[ key ] = false;
            }
        });
        dropdownMenuStates.value[ id ] = !dropdownMenuStates.value[ id ];
    }

    const isDropdownMenuOpen = ( id ) => {
        return !!dropdownMenuStates.value[ id ];
    };

    const onHandleClickOutsideMenu = ( event ) => {
        Object.keys( dropdownMenuStates.value ).forEach( key => {
            const container = dropdownMenuContainer.value[ key ];
            if ( container && !container.contains( event.target )) {
                dropdownMenuStates.value[ key ] = false;
            }
        });
    };

    const onAutomationTag = () => {
        setSearchInputAutomationTag();
        setHeaderAutomationTag();
        setEntriesPerPageSelectAutomationTag();
        setPaginatorAutomationTag();
        setNavigationButtonsAutomationTag();
    }

    const setSearchInputAutomationTag = () => {
        const inputTableSearch = document.querySelector('[id*=vgt-search]');
        if ( inputTableSearch ) {
            inputTableSearch.setAttribute('data-automation', `${ basePrefix }-search`);
        }
    }

    const setHeaderAutomationTag = () => {
        const headersTable = document.querySelectorAll('.vgt-left-align.sortable');
        headersTable.forEach( header => {
            const headerText = header.querySelector('span').textContent.trim();
            header.setAttribute('data-automation', `${ basePrefix }-header-${ headerText }`);
        });
    }

    const setEntriesPerPageSelectAutomationTag = () => {
        const entriesPerPageTableSelect = document.querySelector('[id*=vgt-select-rpp]');
        if ( entriesPerPageTableSelect ) {
            entriesPerPageTableSelect.setAttribute('data-automation', `${ basePrefix }-select-entries`);
            const paginatorOptionsTable = entriesPerPageTableSelect.querySelectorAll('option');
            if ( paginatorOptionsTable ) {
                paginatorOptionsTable.forEach( option => {
                    const optiontext = option.textContent.trim();
                    option.setAttribute('data-automation', `${ basePrefix }-select-option-${ optiontext }`);
                });
            }
        }
    }

    const setPaginatorAutomationTag = () => {
        const paginatorTable = document.querySelector('[id*=vgt-page-input]');
        if ( paginatorTable ) {
            paginatorTable.setAttribute('data-automation', `${ basePrefix }-paginator`);
        }
    }

    const setNavigationButtonsAutomationTag = () => {
        const navigationButtonsTable = document.querySelectorAll('.footer__navigation__page-btn');
        navigationButtonsTable.forEach( button => {
            const buttonText = button.querySelector('span:not([aria-hidden="true"])').textContent.trim();
            button.setAttribute('data-automation', `${ basePrefix }-navigation-${ buttonText }`);
        });
    }

    const onGetSearchOptions = ( placeholder ) => {
        return {
            enabled: true,
            placeholder
        };
    }

    const onGetPaginationOptions = () => {
        return {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            nextLabel: t('general.table.next_label'),
            prevLabel: t('general.table.prev_label'),
            rowsPerPageLabel: t('general.table.rows_per_page_label'),
            pageLabel: t('general.table.page_label'),
            ofLabel: t('general.table.of_label')
        }
    }

    return {
        dropdownMenuContainer,
        isDropdownMenuOpen,
        onAutomationTag,
        onGetPaginationOptions,
        onGetSearchOptions,
        onHandleClickOutsideMenu,
        onSetDropdownMenuContainerRef,
        onToggleDropdownMenu
    }
}
