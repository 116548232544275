import { ref, computed, watch } from 'vue';

export default function useVFInput(props, emit) {
    const size = ref(props.size);
    const valid = ref(props.valid);
    const vfClass = ref(props.vfClass);
    const icon = ref(props.icon);
    const modelValue = ref(props.modelValue);
    const totalyClass = ref(props.totalyClass);

    const onGetClasses = (refValid = false) => {
        let sizeValue = size.value ? `form-control-${size.value}` : '';
        let isValidValue = refValid ? '' : 'vf-error';
        return `${sizeValue} ${isValidValue} ${vfClass.value}`;
    };

    const onGetIcon = computed(() => icon.value || '');

    const onHasIcon = computed(() => (icon.value ? 'input-group' : ''));

    const onUpdateModelValue = (event) => {
        modelValue.value = event.target.value;
        if (event.target.type === 'number') {
            modelValue.value = validateAndLimitInputNumber(modelValue.value);
        }
        emit('update:modelValue', modelValue.value);
    };

    const validateAndLimitInputNumber = (value) => {
        if (value === '') {
            return 0;
        }

        const regex = /^\d+$/;
        const regex_digit = /^\d{2}$/;
        if (regex_digit.test(value)) {
            value = value.slice(1);
        }
        if (!regex.test(value) || value < props.minValue) {
            value = props.minValue;
        }
        if (value > props.maxValue) {
            value = props.maxValue;
        }
        return value;
    }

    watch(() => props.valid, (newValue) => {
        totalyClass.value = onGetClasses(newValue);
    });

    return {
        icon,
        modelValue,
        size,
        totalyClass,
        valid,
        vfClass,
        onGetClasses,
        onGetIcon,
        onHasIcon,
        onUpdateModelValue
    };
}
