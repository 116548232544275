import axios from "axios";
import cfg from "@/service/Configurations";

await cfg.initialize();

const sngApi = axios.create({
    baseURL: cfg.getConfiguration('UI_APP_BACKEND_URL')
});

/**
 * TODO: tomar token de la redirección
 */
sngApi.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${ token }`;
    }
    return config;
});

export default sngApi;
