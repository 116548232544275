import { sngApi } from "@/api";

/**
 *  Create a commodity identification
 */
export const createCommodityIdentification = async ({ commit }, { t, data: dataForm }) => {
    try {
        commit( 'setLoading', true );

        const {
            commodity_identification,
            serie_generator_rule_id
        } = dataForm.value;

        const params = {
            commodity_identification: {
                identification: commodity_identification
            }
        };

        await sngApi.post(
            `/api/series_generator_rules/${ serie_generator_rule_id }/commodity_identifications`,
            params
        );

        commit( 'setLoading', false );

        return {
            ok: true,
            message: t('commodity_identifications.api.create_ok')
        }
    } catch ( error ) {
        let message = t('commodity_identifications.api.create_error');
        if (error.response?.data.message) {
            message += `\n - ${ error.response.data.message }`;
        }

        commit('setLoading', false);

        return {
            ok: false,
            message
        }
    }
}

/**
 *  Get all commodity identifications with possible transitions
 */
export const getCommodityIdentifications = async ({ commit }, { t }) => {
    try {
        commit('setLoading', true);

        const { data: seriesGeneratorRulesData } = await sngApi.get(
            '/api/series_generator_rules?relations=generation_function,commodity_identifications.commodity_identification_state'
        );

        let commodityIdentificationsData = [];

        for ( const rule of seriesGeneratorRulesData ) {
            const processedData = await processCommodityData( rule );
            commodityIdentificationsData = [
                ...commodityIdentificationsData,
                ...processedData
            ];
        }

        const finalData = await Promise.all(
            commodityIdentificationsData.map(
                commodityIdentification => getPossibleTransitionsData( commodityIdentification, t )
            )
        );

        commit('setCommodityIdentifications', finalData);
        commit('setLoading', false);
        return {
            ok: true
        }
    } catch ( error ) {
        commit('setLoading', false);
        return {
            ok: false,
            message: t('commodity_identifications.api.get_all_error')
        }
    }
}


/**
 *  Delete a commodity identification
 */
export const deleteCommodityIdentification = async ({ commit }, { t, commodityIdentification }) => {
    try {
        commit('setLoading', true);

        const {
            commodity_identification_id,
            serie_generator_rule_id
        } = commodityIdentification;

        await sngApi.delete(
            `/api/series_generator_rules/${ serie_generator_rule_id }/commodity_identifications/${ commodity_identification_id }`
        );

        commit('setLoading', false);

        return {
            ok: true,
            message: t('commodity_identifications.api.delete_ok')
        }
    } catch ( error ) {
        commit('setLoading', false);

        return {
            ok: false,
            message: t('commodity_identifications.api.delete_error')
        }
    }
}

/**
 *  Update a commodity identification
 */
export const updateCommodityIdentification = async ({ commit }, { t, commodityIdentification }) => {
    try {
        commit('setLoading', true);

        const {
            commodity_identification,
            commodity_identification_id,
            serie_generator_rule_id
        } = commodityIdentification.value;

        const params = {
            commodity_identification: {
                identification: commodity_identification
            }
        };

        await sngApi.put(
            `/api/series_generator_rules/${ serie_generator_rule_id }/commodity_identifications/${ commodity_identification_id }`,
            params
        );

        commit('setLoading', false);

        return {
            ok: true,
            message: t('commodity_identifications.api.update_ok')
        }
    } catch ( error ) {
        commit('setLoading', false);

        return {
            ok: false,
            message: t('commodity_identifications.api.update_error')
        }
    }
}

/**
 *  Update a commodity identification state
 */
export const updateCommodityIdentificationState = async ({ commit }, { t, commodityIdentification, stateTo }) => {
    try {
        commit('setLoading', true);

        const {
            commodity_identification_id,
            serie_generator_rule_id
        } = commodityIdentification;

        const params = {
            transition: {
                commodity_identification_state_code: stateTo
            }
        };

        await sngApi.patch(
            `/api/series_generator_rules/${ serie_generator_rule_id }/commodity_identifications/${ commodity_identification_id }`,
            params
        );

        commit('setLoading', false);

        return {
            ok: true,
            message: t('commodity_identifications.api.update_ok')
        }
    } catch ( error ) {
        commit('setLoading', false);

        return {
            ok: false,
            message: t('commodity_identifications.api.update_error')
        }
    }
}

/**
 * Processes commodity data based on the provided serie generator rule
 */
export const processCommodityData = async ( serieGeneratorRule ) => {
    const {
        cluster_size,
        commodity_identifications,
        generation_function,
        generation_size,
        id
    } = serieGeneratorRule;

    const haveCommodityIdentifications = Array.isArray( commodity_identifications ) && commodity_identifications.length > 0;
    if ( !haveCommodityIdentifications ) {
        return [];
    }

    return commodity_identifications.map( ci => ({
        serie_generator_rule_id: id,
        serie_generator_rule_name: generation_function.name,
        commodity_identification_id: ci.id,
        commodity_identification: ci.identification,
        commodity_identification_state: ci.commodity_identification_state.name,
        cluster_size,
        generation_size,
    }));
}

/**
 * Get the possible transitions data for a commodity identification
 */
export const getPossibleTransitionsData = async ( commodityIdentification, t ) => {
    try {
        const { data: transitionsData } = await sngApi.get(
            `/api/series_generator_rules/${ commodityIdentification.serie_generator_rule_id }/commodity_identifications/${ commodityIdentification.commodity_identification_id }?relations=possible_transitions`
        );
        const {
            possible_transitions: possibleTransitionsData
        } = transitionsData;

        const possibleTransitions = Object.values( possibleTransitionsData ).map( possibleTransition => ({
            id: possibleTransition.to.code,
            name: possibleTransition.to.name.toUpperCase()
        }));

        return {
            ...commodityIdentification,
            possible_transitions: possibleTransitions
        };
    } catch ( error ) {
        throw new Error( t('commodity_identifications.api.get_all_error') );
    }
}
