<template>
    <li class="dropdown-item px-3 py-2"
        @click.stop="action"
    >
        <i :class="icon"></i> {{ text }}
    </li>
</template>

<script>
export default {
    name: 'vf-dropdown-item',
    props: {
        action: {
            type: Function,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
