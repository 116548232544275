import { createStore } from "vuex";
import commodityIdentifications from "@/modules/commodityIdentifications/store";
import fragmentCodes from "@/modules/fragmentCodes/store";
import generatorRules from "@/modules/generatorRules/store";
import seriesComposition from "@/modules/seriesComposition/store";
import supportedCharacters from "@/modules/supportedCharacters/store";

const store = createStore({
    modules: {
        commodityIdentifications,
        fragmentCodes,
        generatorRules,
        seriesComposition,
        supportedCharacters
    }
});

export default store;
