export const getIsLoading = ( state ) => {
    return state.isLoading;
}

export const getColumns = ( state ) => {
    return state.tableColumns;
}

export const getCommodityIdentifications = ( state ) => {
    return state.commodityIdentifications;
}

export const getCommodityIdentification = ( state ) => {
    return state.commodityIdentification;
}
export const getPossibleTransitions = ( state ) => {
    return state.possibleTransitions;
}
