const formProps = {
    props: {
        size: {
            type: String,
            default: 'default',
        },
        valid: {
            type: Boolean,
            default: true,
        },
        vfClass: {
            type: String,
            default: '',
        },
        totalyClass:{ 
            type: String,
            default: '', 
        },
        titleClass: String,
        titleElement: String,
        name: String,
        id: String,
        type: String,
        placeholder: String,
        modelValue: String|Number,
        isRequired: Boolean,
        disabled: Boolean,
        icon: String,
        iconDir: String,
        textBelow: {
            type: String,
            default: '',
        },
        classBelow: {
            type: String,
            default: 'text-muted',
        },
        classIcon: {
            type: String,
            default: '',
        },
        minValue: {
            type: Number,
            default: 0
        },
        maxValue: {
            type: Number,
            default: 10
        },
        minLength: {
            type: Number,
            default: 0
        },
        maxLength: {
            type: Number,
            default: 50
        },
        widthClass: {
            type: String,
            default: ''
        },
        textSmall: {
            type: String,
            default: ''
        }
    },
};

export default formProps;
