export default () => ({
    commodityIdentification: [],
    commodityIdentifications: [],
    isLoading: false,
    tableColumns: [
        {
            label: 'Commodity Identification',
            field: 'commodity_identification',
            type: 'string'
        },
        {
            label: 'State',
            field: 'commodity_identification_state',
            type: 'string'
        },
        {
            label: 'Generator Rule',
            field: 'serie_generator_rule_name',
            type: 'string'
        },
        {
            label: 'Cluster Size',
            field: 'cluster_size',
            type: 'string'
        },
        {
            label: '',
            field: 'buttons',
            sortable: false
        }
    ],
    possibleTransitions: {
        under_construction: {
            key: 'UCO',
            name: 'UNDER CONSTRUCTION'
        },
        destroyed: {
            key: 'DES',
            name: 'DESTROYED'
        },
        pending: {
            key: 'PEN',
            name: 'PENDING'
        },
        active: {
            key: 'ACT',
            name: 'ACTIVE'
        },
        inactive: {
            key: 'INA',
            name: 'INACTIVE'
        }
    }
});
