<template>
    <label v-if="titleElement" :class="titleClass" :for="id">
        {{ titleElement }}
        <span class="text-primary" v-if="isRequired">*</span>
    </label>

    <p class="mb-0 fst-italic">
        <small v-if="textSmall">{{ textSmall }}</small>
    </p>

    <select
        class="form-select vf-input "
        :class="onGetClasses"
        :name="name"
        :id="id"
        :disabled="disabled"
        :placeholder="placeholder"
        :data-automation="`select-${ id }`"
        @input="onUpdateModelValue"
    >
        <option
            v-for="(option,index) in options"
            :key="index"
            :value="option.value"
            :selected="onSelectedOption(index, option.value, optionSelected)"
            :data-automation="`select-option-${ index }`"
        >
            {{ option.name }}
        </option>
    </select>

    <span v-if="textBelow" :class="classBelow">{{ textBelow }}</span>
</template>

<script>
    import { formProps } from '@/mixins';
    import { useVFSelect } from '@/composables';
    export default {
        name: "VF-select",
        props: {
            options: Object,
            optionSelected: {
                type: String,
                default: ''
            }
        },
        mixins: [
            formProps
        ],
        setup(props, { emit }) {
            const {
                modelValue,
                size,
                valid,
                vfClass,
                onGetClasses,
                onSelectedOption,
                onUpdateModelValue
            } = useVFSelect(props, emit);

            return {
                modelValue,
                size,
                valid,
                vfClass,
                onGetClasses,
                onSelectedOption,
                onUpdateModelValue
            };
        }
    };
</script>
