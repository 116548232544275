import { sngApi } from "@/api";

/**
 *  Get all fragment codes
 */
export const getFragmentCodes = async ({ commit }, { t }) => {
    try {
        const { data } = await sngApi.get('/api/fragment_codes');

        const fragmentCodes = data.reduce((acc, { id, length, supported_character }) => {
            const supportedCharacterId = supported_character.id;
            if ( !acc[ supportedCharacterId ] ) {
                acc[ supportedCharacterId ] = [];
            }

            acc[ supportedCharacterId ].push({
                fragment_code_id: id,
                fragment_code_length: length
            });

            return acc;
        }, {});

        commit('setFragmentCodes', fragmentCodes );

        return {
            ok: true
        }
    } catch (error) {
        let message = t('fragment_codes.api.get_all_error');
        if (error.response?.data.message) {
            message = error.response.data.message;
        }

        return {
            ok: false,
            message
        }
    }
}

/**
 *  Create fragment codes
 */
export const createFragmentCode = async ( _, { fragmentCodeLength, supportedCharacterId }) => {
    return await sngApi.post('/api/fragment_codes', {
        'fragment_code': {
            'supported_character_id': supportedCharacterId,
            'length': fragmentCodeLength
        }
    });
}
