export const setFragmentCodes = ( state, value ) => {
    state.fragmentCodes = value;
}

export const setFragmentCodesSelectList = ( state, fragmentCodes ) => {
    state.fragmentCodesSelectList = {
        ...state.fragmentCodesSelectList,
        ...fragmentCodes
    };
}

export const deleteFragmentCodeSelectListBySupportedCharactersId = ( state, supportedCharactersId ) => {
    if ( state.fragmentCodesSelectList[ supportedCharactersId ] ) {
        delete state.fragmentCodesSelectList[ supportedCharactersId ];
    }
}
