/**
 *  Create serie composition
 */
export const createSerieComposition = async ({ commit }, { t, store, data }) => {
    try {
        commit('setLoading', true);

        const { fragment_codes, supported_characters } = data;

        const { data: dataSupportedCharacters } = await store.dispatch(
            'supportedCharacters/createSupportedCharacter',
            supported_characters
        );

        const dataFragmentCodes = await createFragmentCodes(
            store,
            dataSupportedCharacters,
            fragment_codes
        );

        commit('setLoading', false);

        return {
            ok: true,
            message: t('series_composition.api.create_ok'),
            data: { dataSupportedCharacters, dataFragmentCodes }
        }
    } catch (error) {
        let message = t('series_composition.api.create_error');
        if (error.response?.data.message) {
            message += `\n - ${ error.response.data.message }`;
        }

        commit('setLoading', false);

        return {
            ok: false,
            message
        }
    }
}

/**
 *  Create fragment codes
 */
async function createFragmentCodes( store, dataSupportedCharacters, fragment_codes ) {
    const supportedCharacterId = dataSupportedCharacters.id;
    const fragmentCodePromises = fragment_codes.map( async ( fragmentCode ) => {
        const fragmentCodeLength = fragmentCode.value;
        const { data: dataFragmentCode } = await store.dispatch(
            'fragmentCodes/createFragmentCode',
            { supportedCharacterId, fragmentCodeLength }
        );
        return dataFragmentCode;
    });

    const dataFragmentCodes = await Promise.all( fragmentCodePromises );
    return dataFragmentCodes;
}
