const Create = () => import(/* webpackChunkName: "SeriesCompositionCreate" */ '@/modules/seriesComposition/views/Create.vue');

export default [
    {
        path: '/series-composition',
        name: "series_composition",
        component: Create,
        meta : {
            in_menu : true,
            public: false,
            icon : 'fa-solid fa-gear',
            requiresAuth: true
        }
    }
]

