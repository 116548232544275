import { sngApi } from "@/api";

/**
 *  Get all supported characters
 */
export const getSupportedCharacters = async ({ commit }, { t, store }) => {
    commit('setLoading', true);

    const resp = await store.dispatch('supportedCharacters/getSupportedCharacters', { t });

    commit('setLoading', false);

    return resp;
}

/**
 *  Get all fragment codes
 */
export const getFragmentCodes = async ({ commit }, { t, store }) => {
    commit('setLoading', true);

    const resp = await store.dispatch('fragmentCodes/getFragmentCodes', { t });

    commit('setLoading', false);

    return resp;
}

/**
 *  Get all generator rules
 */
export const getGeneratorRules = async ({ commit }, { t }) => {
    try {
        const { data } = await sngApi.get(
            '/api/series_generator/rules?relations=generation_function'
        );

        const generatorRules = data.map(item => ({
            value: item.id,
            name: `${ item.generation_function.name }_${ item.cluster_size }_${ item.generation_size }`
        }));

        commit( 'setGeneratorRules', generatorRules );

        return {
            ok: true
        }
    } catch ( error ) {
        let message = t('generator_rules.api.get_all_error');
        if ( error.response?.data.message ) {
            message = error.response.data.message;
        }

        return {
            ok: false,
            message
        }
    }
}

/**
 *  Get all fragment codes for a supported character
 */
export const getFragmentCodesBySupportedCharacterId = async ({ commit }, { t, store, supportedCharactersId }) => {
    commit('setLoading', true);

    const fragmentCodes = await store.getters['fragmentCodes/getFragmentCodesBySupportedCharacterId']( supportedCharactersId );

    if ( !fragmentCodes ) {
        commit('setLoading', false);

        return {
            ok: false,
            message: t('fragment_codes.api.get_all_error')
        };
    }

    const fragmentCodesList = fragmentCodes.reduce((acc, { fragment_code_id, fragment_code_length }) => {
        if ( !acc[ supportedCharactersId ] ) {
            acc[ supportedCharactersId ] = [];
        }

        acc[ supportedCharactersId ].push({
            value: fragment_code_id,
            name: fragment_code_length
        });

        return acc;
    }, {});

    store.commit('fragmentCodes/setFragmentCodesSelectList', fragmentCodesList );
    commit('setLoading', false);

    return {
        ok: true
    };
}

/**
 *  Create a generator rule
 */
export const createGenerationRule = async ({ commit }, { t, data: dataForm }) => {
    try {
        commit( 'setLoading', true );

        const fragmentCodes = dataForm.map(( item, index ) => ({
            fragment_code_id: item.fragment_code_id,
            fragment_position: index + 1
        }));

        /**
         * TODO:
         ** el generation_function_code se debe reemplazar por el valor elegido (no hardcodeado)
         */
        const params = {
            series_generator_rule: {
                generation_function_code: 'SNP',
                cluster_size: dataForm.totalClusterSize,
                fragment_codes: fragmentCodes
            }
        };

        await sngApi.post('/api/series_generator_rules', params );

        commit( 'setLoading', false );

        return {
            ok: true,
            message: t('generator_rules.api.create_ok')
        }
    } catch ( error ) {
        let message = t('generator_rules.api.create_error');
        if ( error.response?.data.errors ) {
            Object.entries(error.response.data.errors).forEach(([ , values ]) => {
                values.forEach( value => {
                    message += `\n - ${ value }`;
                });
            });
        }

        if ( error.response?.data.message ) {
            message += `\n - ${ error.response.data.message }`;
            message = message.replace(/["[\]]/g, '');
        }

        commit('setLoading', false);

        return {
            ok: false,
            message
        }
    }
}
